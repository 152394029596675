<template>
  <div>
    <!--  checkbox -->
    <v-checkbox
      v-show="showCheckBox"
      v-model="fieldValue"
      :label="fieldLabel"
      :readonly="readonly"
      :disabled="disabled"
      :error-messages="computedErrorMessages"
      :rules="rules"
      dense
    >
      <template v-slot:append v-if="visibleRemoveFieldButton">
        <remove-field-button
          :field="field"
          @click="removeField"
        ></remove-field-button>
      </template>
    </v-checkbox>

    <!--  text-field  -->
    <v-text-field
      v-show="showTextBox"
      v-model="fieldValue"
      :label="fieldLabel"
      :readonly="readonly"
      :disabled="disabled"
      :type="textFieldType"
      :error-messages="computedErrorMessages"
      :rules="rules"
      :loading="computedLoading"
      dense
    >
      <template
        v-slot:append-outer
        v-if="visibleRemoveFieldButton || showDatabaseLookup"
      >
        <!-- remove Field command button -->
        <remove-field-button
          v-if="visibleRemoveFieldButton"
          :field="field"
          @click="removeField"
        ></remove-field-button>

        <!-- database Lookup command button -->
        <base-tooltip-button
          v-if="showDatabaseLookup"
          fab
          small
          :loading="loadingDatabaseLookupData"
          @click="databaseLookup"
        >
          <template v-slot:icon-name>
            {{ iconDatabaseLookup }}
          </template>
          <template v-slot:toolTip>
            {{ tooltipDatabaseLookup }}
          </template>
        </base-tooltip-button>
      </template>
    </v-text-field>

    <!--  Lookup Field  -->
    <!-- :prepend-icon="lookupIcon" -->
    <!-- :messages="lookupErrorMessage"-->
    <v-autocomplete
      v-show="showLookup"
      v-model="fieldValue"
      :rules="rules"
      :label="fieldLabel"
      :items="lookupItems"
      :loading="computedLoading"
      :clearable="!lookupEnforce"
      :error-messages="lookupErrorMessage"
      item-text="id"
      item-value="value"
      color="primary"
      dense
    >
      <template
        v-slot:append-outer
        v-if="visibleRemoveFieldButton || showDatabaseLookup"
      >
        <!-- remove Field command button -->
        <remove-field-button
          v-if="visibleRemoveFieldButton"
          :field="field"
          @click="removeField"
        ></remove-field-button>

        <!-- database Lookup command button -->
        <base-tooltip-button
          v-if="showDatabaseLookup"
          fab
          small
          :loading="loadingDatabaseLookupData"
          @click="databaseLookup"
        >
          <template v-slot:icon-name>
            {{ iconDatabaseLookup }}
          </template>
          <template v-slot:toolTip>
            {{ tooltipDatabaseLookup }}
          </template>
        </base-tooltip-button>
      </template>
    </v-autocomplete>

    <!--  text area - displays Memo Text  -->
    <v-textarea
      v-show="showTextarea"
      v-model="fieldValue"
      dense
      outlined
      counter
      :label="fieldLabel"
      :readonly="readonly"
      :disabled="disabled"
      :loading="computedLoading"
      :error-messages="computedErrorMessages"
      :messages="computedMessages"
      :success-messages="successMessages"
      :rows="textareaRowCount"
      :rules="rules"
      @click="onTextareaClick"
    >
      <!-- More Text -->
      <template v-slot:append v-if="visibleMoreText">
        <base-tooltip-button fab small @click="moreText">
          <template v-slot:icon-name>
            {{ iconMore }}
          </template>
          <template v-slot:toolTip>
            {{ tooltipMoreText }}
          </template>
        </base-tooltip-button>
      </template>

      <!-- Remove field command button -->
      <template v-slot:append-outer v-if="visibleRemoveFieldButton">
        <remove-field-button
          :field="field"
          @click="removeField"
        ></remove-field-button>
      </template>
    </v-textarea>

    <!--  date-picker -->
    <div v-show="showDatePicker">
      <date-picker
        :item="field"
        :default-current-date="false"
        :disabled="disabled"
        :readonly="readonly"
        :fieldLabel="fieldLabel"
        :rules="rules"
        fieldName="value"
        dense
      >
        <template v-slot:append-outer v-if="visibleRemoveFieldButton">
          <remove-field-button
            :field="field"
            @click="removeField"
          ></remove-field-button>
        </template>
      </date-picker>
    </div>
  </div>
</template>
<script>
// design
import {
  iconDatabaseLookup,
  iconDotsHorizontal
} from "@/design/icon/iconConst";

// model
import { fieldType, maxMemoFieldLength } from "@/model/field/fieldModel";
import { findRecordMode, recordMode } from "@/model/record/recordModel";
import {
  enforceLookup,
  formFieldRules,
  lookupItemCount,
  lookupItems
} from "@/model/field/formFieldModel";
import { ruleNames } from "@/model/rules/ruleModel";
import { eventNames } from "@/model/common/events/eventConst";

// mixins
import { deleteSettingsMixin } from "@/mixins/shared/base/settings/deleteSettingsMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";

export default {
  name: "FormField",
  mixins: [deleteSettingsMixin, userMixin],
  components: {
    DatePicker: () => import("@/components/shared/core/pickers/DatePicker"),
    RemoveFieldButton: () => import("@/components/field/RemoveFieldButton"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton")
  },
  data() {
    return {
      fieldType: fieldType,
      iconMore: iconDotsHorizontal,
      iconDatabaseLookup: iconDatabaseLookup,
      errorMessages: undefined,
      messages: undefined,
      successMessages: undefined,
      selectLookupItem: undefined,
      isLoading: false
    };
  },
  props: {
    /**
     * Record Field Value
     * @type {{id: number, name: string, label: string, fieldDataType: number, value: any}}
     */
    field: undefined,
    /**
     * Record Category Form Field
     * @type {{id: number, name: string, label: string, fieldDataType: number, fieldDataTypeName: string, flags:number, immutable: boolean, isPersistentField: boolean, isRequired: boolean, isSystemCategoryType: boolean, isSystemField: boolean, isVolatile: boolean, requiredForAutoFiling: boolean, sequence: number, unselectable: boolean, searchOperators: {description: string, operator: string}[], operations: {name:string, allowed:boolean, valid:boolean}[], lookup: {databaseLookup:boolean, enforce:boolean, items: string[]}[]}}
     */
    formField: undefined,
    /**
     * Current record's Form mode
     */
    formMode: recordMode.view,
    /**
     * Is Field Read Only
     */
    readonly: {
      type: Boolean,
      default: true
    },
    /**
     * Is Field disabled
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Is removable field
     */
    removable: {
      type: Boolean,
      default: false
    },
    /**
     * Display loading progress when loading field value
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * show Database Lookup command button
     */
    showDatabaseLookup: {
      type: Boolean,
      default: false
    },
    /**
     * loading Database Lookup Data indicator
     */
    loadingDatabaseLookupData: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedLoading() {
      return this.loading ? this.loading : this.isLoading;
    },
    computedFormField() {
      return this.formField ?? this.findFormField(this.field?.id ?? -1);
    },
    /**
     * Get Form input components rules
     * @return {*[]} mixed array of types function, boolean and string
     */
    rules() {
      return this.readonly ? [] : formFieldRules(this.computedFormField);
    },
    fieldTypeId() {
      return this.field?.fieldDataType ?? -1;
    },
    showCheckBox() {
      return this.fieldTypeId === fieldType.BIT;
    },
    showTextBox() {
      return this.showLookup
        ? false
        : this.fieldTypeId === fieldType.LITERAL ||
            this.fieldTypeId === fieldType.CURRENCY ||
            this.fieldTypeId === fieldType.NUMBER ||
            this.fieldTypeId === fieldType.INTEGER;
    },
    showTextarea() {
      return this.fieldTypeId === fieldType.TEXT;
    },
    showDatePicker() {
      return this.fieldTypeId === fieldType.DATE;
    },
    showLookup() {
      return this.readonly ? false : this.lookupItemCount > 0;
    },
    fieldLabel() {
      return this.field?.name ?? "Field ?";
    },
    fieldValue: {
      get() {
        if (this.fieldTypeId === fieldType.TEXT) {
          const text = this.field?.value ?? "";
          if (!this.field.textLoaded) {
            return `${text} ...`;
          }
        }

        return this.field?.value ?? undefined;
      },
      set(value) {
        if (this.field) {
          this.field.value = value;
        }
      }
    },
    textFieldType() {
      return this.fieldTypeId === fieldType.NUMBER ||
        this.fieldTypeId === fieldType.INTEGER
        ? "number"
        : "string";
    },
    visibleRemoveFieldButton() {
      return this.removable;
    },
    visibleMoreText() {
      return this.fieldTypeId === fieldType.TEXT
        ? !this.field?.textLoaded
        : false;
    },
    /**
     * tooltip of More Text
     * @return {string} tooltip
     */
    tooltipMoreText() {
      return `Load to ${this.recordModeName} full ${this.fieldLabel}`;
    },
    /**
     * tooltip Database Lookup
     * @return {string}
     */
    tooltipDatabaseLookup() {
      return `Performs Database Lookup`;
    },
    /**
     * Record Form mode name (recordMode.view/new/edit)
     * @return {string} return Record mode name
     */
    recordModeName() {
      return findRecordMode(this.formMode)?.name ?? "view";
    },
    computedErrorMessages() {
      return this.errorMessages;
    },
    computedMessages() {
      return this.visibleMoreText
        ? `${this.fieldLabel} not fully loaded. Click on text or on ellipsis button to load all text`
        : undefined;
    },
    /**
     * Textarea rows count
     * @return {number} textarea rows count
     */
    textareaRowCount() {
      return (this.field?.value?.length ?? 0) > maxMemoFieldLength ? 10 : 5;
    },

    /**
     * field Message/hint
     * @return {string|undefined}
     */
    fieldMessage() {
      if (this.formMode === recordMode.new) {
        if (this.computedFormField?.requiredForAutoFiling ?? false) {
          return ruleNames.requiredByAutoFiling;
        }
      }
      if (this.formMode !== recordMode.view) {
        if (this.computedFormField?.isRequired ?? false) {
          return ruleNames.required;
        }
      }
      return undefined;
    },

    lookupItems() {
      return lookupItems(this.computedFormField);
    },

    lookupItemCount() {
      return lookupItemCount(this.computedFormField);
    },

    lookupEnforce() {
      return this.readonly ? false : enforceLookup(this.computedFormField);
    },

    lookupErrorMessage() {
      if (!this.showDatabaseLookup) {
        return undefined;
      }

      return (this.fieldValue?.toString()?.length ?? 0) === 0
        ? `Database Lookup Field`
        : undefined;
    }
  },
  methods: {
    removeField() {
      this.$emit(eventNames.removeField, this.field);
    },
    databaseLookup() {
      this.$emit(eventNames.databaseLookup);
    },
    moreText() {
      this.$emit(eventNames.moreText, this.field);
    },
    onTextareaClick() {
      try {
        if (!this.field?.textLoaded) {
          this.moreText();
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  async created() {
    try {
      if (!this.computedFormField) {
        try {
          this.isLoading = true;
          await this.setFormField(this.field.id);
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
};
</script>
